import * as client_hooks from '../../../src/hooks.client.ts';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47')
];

export const server_loads = [0];

export const dictionary = {
		"/(site)/[[lang=lang]]/artigos/pesquisa": [~19,[2,5]],
		"/(site)/[[lang=lang]]/artigos/sobre/[type]/[description]/[[modifier]]": [~20,[2,5]],
		"/(site)/[[lang=lang]]/artigos/[[category=categories]]": [~17,[2,5]],
		"/(site)/[[lang=lang]]/artigos/[[category=categories]]/[article]/[[preview=preview]]": [~18,[2,5]],
		"/(site)/[[lang=lang]]/(newsletter)/cancelar/[hash]": [~9,[2]],
		"/(site)/[[lang=lang]]/centros-de-arbitragem": [21,[2]],
		"/(site)/[[lang=lang]]/contacto": [22,[2]],
		"/(site)/[[lang=lang]]/credito-consolidado": [23,[2]],
		"/(site)/[[lang=lang]]/credito-habitacao": [24,[2]],
		"/(site)/[[lang=lang]]/credito-pessoal": [25,[2]],
		"/ffy": [36,[6]],
		"/(site)/[[lang=lang]]/informacoes-legais": [26,[2]],
		"/(site)/[[lang=lang]]/intermediario-de-credito": [27,[2]],
		"/landing/credito-consolidado": [37,[7]],
		"/landing/credito-habitacao": [38,[7]],
		"/landing/ebook/credito-habitacao-pre-contratacao": [39,[7]],
		"/landing/mortgage": [40,[7]],
		"/landing/poupar-credito-habitacao": [41,[7]],
		"/landing/pretimmo": [42,[7]],
		"/landing/seguro-vida": [43,[7]],
		"/landing/seguros": [~44,[7]],
		"/(site)/[[lang=lang]]/perguntas": [~28,[2]],
		"/pnmadmin": [45],
		"/(site)/[[lang=lang]]/politica-cookies": [29,[2]],
		"/(site)/[[lang=lang]]/politica-de-protecao-de-dados": [30,[2]],
		"/(site)/[[lang=lang]]/recrutamento": [31,[2]],
		"/(site)/[[lang=lang]]/remodelacao": [~32,[2]],
		"/(site)/[[lang=lang]]/seguros": [33,[2]],
		"/sentry-example": [46],
		"/(site)/[[lang=lang]]/(simulador)/simuladores": [~16,[2,3]],
		"/(site)/[[lang=lang]]/(simulador)/simulador/[[embed]]/compra": [11,[2,3,4]],
		"/(site)/[[lang=lang]]/(simulador)/simulador/consulta/[simulationId]": [~15,[2,3]],
		"/(site)/[[lang=lang]]/(simulador)/simulador/[[embed]]/credito-consolidado": [12,[2,3,4]],
		"/(site)/[[lang=lang]]/(simulador)/simulador/[[embed]]/novo-credito-pessoal": [13,[2,3,4]],
		"/(site)/[[lang=lang]]/(simulador)/simulador/[[embed]]/seguro-vida-habitacao": [14,[2,3,4]],
		"/(site)/[[lang=lang]]/(simulador)/simulador/[[embed]]/[simulation]": [~10,[2,3,4]],
		"/(site)/[[lang=lang]]/sobre": [34,[2]],
		"/(site)/[[lang=lang]]/termos-e-condicoes": [35,[2]],
		"/translate": [47],
		"/(site)/[[lang=lang]]": [8,[2]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';